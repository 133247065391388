<template>
	<div class="main">
		<template  v-if="dataList">
		<div class="info-box">
			<div class="common-head common-border">提交人信息</div>
			<div class="flex goods-card-title item-line">
				<span class="info-box-left-text">申请人：</span>
				<span class="info-box-right-text">{{dataList.userName}}</span>
			</div>
			<div class="flex goods-card-title item-line">
				<span class="info-box-left-text">所属门店：</span>
				<span class="info-box-right-text">{{dataList.shopName}}</span>
			</div>
			<div class="flex goods-card-title item-line">
				<span class="info-box-left-text">申请时间：</span>
				<span class="info-box-right-text">{{dataList.createTime}}</span>
			</div>
			<div class="flex goods-card-title item-line">
				<span class="info-box-left-text">申请金额：</span>
				<span class="info-box-right-text">{{dataList.money}}</span>
			</div>
		</div>
		<div class="info-box common-margin-top">
			<div class="common-head common-border">付款凭证</div>
			<div class="pic-box" @click="toImg(dataList.chargeCert)">
				<van-image
					height="100%"
					fit="contain"
					:src="dataList.chargeCert"
				/>
			</div>
		</div>
		<div class="order-status">
			<div class="common-head common-border">审批进度</div>
			<div>
				<van-steps direction="vertical" active-color="#2586FF" :active="stepId">
					<van-step v-for="(item, index) in activities" :key="index">
						<h3>{{item.levelName}}</h3>
                        <div v-for="(item2, index2) in activities[index].auditorList" :key="index2">
                            <div class="steps-info-ok" v-if="item2.checkFlag === 1">
                                <div v-if="item2.auditorName !== ''">{{item2.auditorName}} <span style="color: green">已通过</span></div>
                                <div v-if="item2.auditorName !== ''">{{item2.createTime}}</div>
                            </div>
							<div v-else-if="item2.checkFlag === 2">
								<div class="steps-info-rt">
									<div>{{item2.auditorName}} <span style="color: red">已驳回</span></div>
									<div><van-icon name="cross" /></div>
								</div>
								<div class="no-message">驳回原因：{{item2.message}}</div>
							</div>
                            <div v-else class="steps-info-no">
                                <div>{{item2.auditorName}}</div>
                            </div>
                        </div>
						<div  v-if="index == 0 && stepId==0" class="steps-info">
							<van-uploader v-model="url" :max-count="1" :after-read="afterRead" />
						</div>
						<div @click="toImg(dataList.approvalUrl)" v-if="dataList.approvalUrl && index == 0">
							<van-image
									height="50px"
									fit="contain"
									:src="dataList.approvalUrl"
							/>
						</div>
					</van-step>
				</van-steps>
			</div>
		</div>
		<div class="margin-box"></div>
		<div v-if="appStatus !== 3 && appStatus !== 4" class="button-box">
			<div @click="toOk" class="button-ok">通过</div>
			<div @click="toShowNo" class="button-no">驳回</div>
		</div>
		<van-popup
				v-model="show"
				closeable
				lock-scroll
				round
				close-icon="close"
				:style="{ height: '200px', width:'80vw' }"
		>
			<div class="message-box-text">
				<van-field
						v-model="addData.rejectReason"
						rows="2"
						autosize
						type="textarea"
						placeholder="请输入驳回原因"
				/>
			</div>
			<div class="message-box-button">
				<div @click="show = false" class="button-cancel">取消</div>
				<div @click="toNo" class="button-ok">确认</div>
			</div>
		</van-popup>
		</template>
		<template v-else>
			<empty description="暂无数据" />
		</template>
	</div>
</template>

<script>
	import COS from 'cos-js-sdk-v5'
	import { getApprovalV2 } from '@/api/service'
	import { getRedInfo, redPass, redNo, getCosToken } from '@/api/shop'
	import {Dialog, Tag, Toast} from 'vant';
	import { Empty } from 'vant';
	import { ImagePreview } from 'vant';
	export default {
	  name: 'DemandList',
	  components: {
		  // Tag,
		  Empty
	  },
	  data() {
	    return {
			cos: {}, // 存储桶对象
			id: '', // id
			accountNo: '', // 单号
			activities: '', // 流程列表
			dataList: {},
			addData: {
				rechargeNo: "",
				url: '',
				userId: '',
				rejectReason: '',
			},
			rejectRemark: '',
			show: false,
			stepId: 0,
			appStatus: 0, // 审批流状态
			url:[]
	    }},
	  created() {
		  const query = this.$route.query
		  if (!query.allotNo) {
			  Dialog.alert({title: '提示', message: '参数错误',})
			  return
		  }
		  this.id = query.allotNo
		  this.accountNo = query.allotNo
		  this.addData.rechargeNo = query.allotNo
		  this.addData.userId = sessionStorage.getItem("userId")
		  console.log(this.addData)
		  this.getInfo()
		  this.showApproval()
		  this.initCOS()
	  },
	  mounted:function(){
	  },
	  methods: {
	  	// 打开图片
		  toImg(url){
			  ImagePreview({
				  images: [url],
				  closeable: true,
			  });
		  },
	  	// 获取信息
		  getInfo(){
			  const postData = {}
			  postData.rechargeNo = this.id
			  getRedInfo(postData).then((response) => {
				  this.dataList = response.data
			  })
		  },
		  // 查看流程
		  showApproval() {
			  const postData = {}
			  postData.id = this.accountNo
			  getApprovalV2(postData).then(response => {
				  console.log(response)
				  this.activities = response.data.list
				  this.appStatus = response.data.status
                  // 判断进行到了哪一步
                  for (let i = 0; i < this.activities.length; i++) {
                  	if(this.activities[i].checkFlag === 1 || this.activities[i].checkFlag === 2){
                  		this.stepId = i
					}
				  }
			  })
		  },
		  // 提交通过
		  toOk(){
			  Dialog.confirm({
				  title: '通过',
				  message: '是否确认通过',
			  })
			  .then(() => {
				  // on confirm
				  this.saveData(2)
			  })
			  .catch(() => {
				  // on cancel
			  });
		  },
		  // 展示拒绝
		  toShowNo(){
			  this.show = true
		  },
		  // 提交拒绝
		  toNo(){
			  this.show = false
			  this.saveNo()
		  },
		  // 提交通过
		  saveData() {
			  redPass(this.addData).then((response) => {
			  	if(response.code === 200 || response.code === 0){
					console.log(response)
					Dialog.alert({title: '提示', message: '操作成功',})
					this.getInfo()
					this.showApproval()
					let param = {}
					param.msgType = 7
					this.$router.push({ path: '/examineList', query: param });
				}
			  })
		  },
		  // 提交拒绝
		  saveNo(type) {
			  redNo(this.addData).then((response) => {
				  if(response.code === 200 || response.code === 0){
					  console.log(response)
					  Dialog.alert({title: '提示', message: '操作成功',})
					  this.getInfo()
					  this.showApproval()
				  }
				  let param = {}
				  param.msgType = 7
				  this.$router.push({ path: '/examineList', query: param });
			  })
		  },
		  // 监听上传
		  afterRead(file) {
			  console.log(file);
			  const typeArr = ['image/png', 'image/jpeg', 'image/jpg']
			  const isJPG = typeArr.indexOf(file.file.type) !== -1
			  if (!isJPG) {
				  return
			  }
			  // 将文件上传至服务器
			  this.toUpload(1, file)
		  },
		  // 提交上传
		  toUpload(type, file) {
			  const _this = this
			  Toast.loading({
				  message: '加载中...',
				  forbidClick: true,
				  duration: 0
			  });
			  this.cos.putObject({
				  Bucket: 'tousubiaoyang-1301970825', /* 填入您自己的存储桶，必须字段 */
				  Region: 'ap-nanjing', /* 存储桶所在地域，例如ap-beijing，必须字段 */
				  Key: 'chartVideo/' + new Date().getTime() + this.GetRandomNum(10000,99999), /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */
				  StorageClass: 'STANDARD',
				  Body: file.file, // 上传文件对象
				  onProgress: function(progressData) {
					  console.log(JSON.stringify(progressData))
				  }
			  }, function(err, data) {
				  Toast.clear()
				  console.log(err || data)
				  let newUrl = data.Location.replace(/tousubiaoyang-1301970825.cos.ap-nanjing.myqcloud.com/, 'tousubiaoyang-1301970825.file.myqcloud.com')
				  newUrl = 'https://' + newUrl
				  _this.addData.url = newUrl
				  console.log(newUrl)
			  })
		  },
		  // 获取随机数
		  GetRandomNum(Min,Max) {
			  var Range = Max - Min;
			  var Rand = Math.random();
			  return(Min + Math.round(Rand * Range));
		  },
		  // 初始化存储桶
		  initCOS() {
			  // 初始化实例
			  const _this = this
			  _this.cos = new COS({
				  // 必选参数
				  getAuthorization: function(options, callback) {
					  console.log(222)
					  getCosToken({}).then(response => {
						  console.log(response)
						  if (response.code === 200 || response.code === 0) {
							  //  console.log(json)
							  const credentials = response.data
							  callback({
								  TmpSecretId: credentials.tmpSecretId,
								  TmpSecretKey: credentials.tmpSecretKey,
								  XCosSecurityToken: credentials.sessionToken,
								  // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
								  StartTime: credentials.startTime, // 时间戳，单位秒，如：1580000000
								  ExpiredTime: credentials.expiredTime // 时间戳，单位秒，如：1580000900
								  // ScopeLimit: true, // 细粒度控制权限需要设为 true，会限制密钥只在相同请求时重复使用
							  })
						  }
					  })
				  }
			  })
		  },
	  }
	}
</script>

<style scoped>
	.main{
		padding: 20px;
		color:#333333;
	}
	.flex{
		display: flex;
	}
	.goods-card-title{
		justify-content: space-between;
	}
	.item-line{
		height: 30px;
		line-height: 30px;
		margin-top: 10px;
		font-size: 16px;
	}
	.order-status{
		margin: 20px 0 0 0;
		background: #FFFFFF;
		border-radius: 8px;
		padding:15px;
	}
	.order-status-title{
		display: flex;
		font-weight: bold;
		font-size: 16px;
	}
	.info-box-title-icon{
		width: 5px;
		height: 20px;
		background: #2586FF;
		color: #2586FF;
		margin-right: 10px;
	}
	.button-box{
		width: calc(100vw - 40px);
		position: fixed;
		bottom: 0;
		background-color: #ffffff;
		display: flex;
		justify-content: space-around;
		padding: 20px 0;
	}
	.button-ok{
		background-color: #237FF0;
		color: #ffffff;
		border-radius: 5px;
		height: 32px;
		line-height: 32px;
		padding: 0 20px;
	}
	.button-no{
		background-color: #FF7833;
		color: #ffffff;
		border-radius: 5px;
		height: 32px;
		line-height: 32px;
		padding: 0 20px;
	}
	.button-cancel{
		background-color: #abadaf;
		color: #ffffff;
		border-radius: 5px;
		height: 32px;
		line-height: 32px;
		padding: 0 20px;
	}
	.margin-box{
		width: 100%;
		height: 100px;
	}
	.message-box-text{
		margin-top: 40px;
	}
	.message-box-button{
		display: flex;
		justify-content: space-around;
		width: 100%;
		margin-top: 38px;
	}
    .steps-info-ok{
        height: 30px;
        line-height: 30px;
        display: flex;
        justify-content: space-between;
        color: #237FF0;
    }
	.steps-info-rt{
		height: 30px;
		line-height: 30px;
		display: flex;
		justify-content: space-between;
		color: #f83b51;
	}
    .steps-info-no{
        height: 25px;
        line-height: 25px;
    }
	.no-message{
		color: #f83b51;
	}
	.item-line-right-box{}
	.return-res-text{
		padding: 10px 0;
	}
	.info-box{
		background-color: #ffffff;
		padding:15px;
		border-radius: 4px;
	}
	.info-box-left-text{
		color: #98999A;
	}
	.info-box-right-text{
		color: #333333;
	}
	.common-head {
		height: 30px;
		font-size: 14px;
		font-weight: bold;
		color: #666;
	}
	.common-border {
		border-bottom: 1px solid rgba(239, 240, 245, 1);
	}
	.common-margin-top{
		margin-top: 15px;
	}
	.pic-box{
		width: 100%;
		height: 160px;
		background: #F2F2F2;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.pic-box image{
		height: 160px;
	}
</style>
